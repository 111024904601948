import React from "react"
import { Link, navigate } from "gatsby"
import Layout from "../layout"
import SEO from "../seo"
import "../../assets/css/arabic-fonts.css"
import imgQuizCover from "../../assets/images/gen-mars/quiz-result/cover.png"
import { Calendar, momentLocalizer } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import moment from "moment"
import { lang, setLang, lng, setEventScrollPosition } from "../essentials"
import { document, window } from "browser-monads"
import { backendHost } from "../../config"
import axios from "axios"
import { validators } from "../register-form"

export default function GenHope(props) {
  const { events } = props.pageContext
  setLang(props.pageContext.lang)

  const prefix = lng("", "/ar")

  const localizer = momentLocalizer(moment)
  const eventsList = []

  events.map(event => {
    event.date_marker.map(mark => {
      const date1 = mark.date
        .split("-")
        .map(Number)
        .map((e, index) => (index == 1 ? e - 1 : e))
      eventsList.push({
        title: lng(mark.text, mark.arabic_text),
        allDay: true,
        start: new Date(...date1),
        end: new Date(date1[0], date1[1], date1[2] + 1),
        url: event.url,
        section: mark.section,
      })
    })
  })

  const subscribeAction = async () => {
    const value = document.getElementById("subscribe-input").value
    if (validators.email.test(value)) {
      await axios
        .post(backendHost + "/subscribers", {
          // await axios.post('http://localhost:1337/subscribers', {
          email: value,
        })
        .then(response => {
          window.UIkit.modal.alert(
            "You have successfully subscribed to the newsletter!"
          )
        })
        .catch(err => {
          window.UIkit.modal.alert("An error occured!")
        })
    } else {
      window.UIkit.modal.alert("Please enter a valid email")
    }
  }

  return (
    <Layout lang={lang}>
      <SEO title="Generation Hope" lang={lang} />
      <main>
        <div
          className="uk-cover-container"
          data-uk-height-viewport="offset-top: true; offset-bottom: 40"
        >
          <img src={imgQuizCover} alt="" data-uk-cover />
          <div className="uk-overlay uk-position-cover header-overlay"></div>
        </div>
        <section style={{ marginTop: "-250px", position: "relative" }}>
          <div style={{ maxWidth: "800px", margin: "auto" }}>
            <div className="uk-text-center ">
              <h2 className=" mb4-ns">
                {lng("Generation Hope", "جيل الأمل")}
                <div
                  className="text-underline-heavy"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                ></div>
              </h2>
              <h2 className="mb2">{lng("Programmes", "البرامج")}</h2>
            </div>
          </div>
          <div
            className={lng("uk-text-center pb4", "uk-text-center pv5")}
            style={{ maxWidth: "800px", margin: "auto" }}
          >
            <div
              className="events-container uk-grid uk-grid-column-small uk-child-width-1-3@s uk-child-width-1-2"
              data-uk-grid
            >
              {events.map(event => (
                <div>
                  <div
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <Link to={prefix + "/gen-hope/programmes/" + event.url}>
                      <div className="animated-card animated-card-overlay-trigger event-card">
                        <div
                          className="uk-inline w-100"
                          style={{
                            overflow: "hidden",
                            position: "relative",
                            background: "#000",
                          }}
                        >
                          <img
                            src={event.thumbnail.publicURL}
                            className="w-100"
                            alt=""
                          />
                          <div
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              top: "0",
                              left: "0",
                            }}
                          >
                            <span
                              className={lng(
                                "event-title font-martian",
                                "event-title font-cairo"
                              )}
                            >
                              {lng(event.name, event.arabic_name)}
                            </span>
                          </div>
                          <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                            {/* <img src={zoomButton} width="50px" height="50px" alt="play button" /> */}
                            <div
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                top: "0",
                                left: "0",
                              }}
                            >
                              <span
                                className={lng(
                                  "event-title font-martian",
                                  "event-title font-cairo"
                                )}
                              >
                                {lng(event.name, event.arabic_name)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="gen-mars-calendar pb6">
            <div
              className="standard-container transparent-bg"
              style={{ marginTop: "300px" }}
            >
              <div style={{ marginTop: "-400px" }}>
                <Calendar
                  localizer={localizer}
                  events={eventsList}
                  startAccessor="start"
                  views={["month"]}
                  endAccessor="end"
                  style={{ height: "600px", marginBottom: "50px" }}
                  onSelectEvent={e => {
                    setEventScrollPosition(e.section)
                    navigate(lng("", "/ar") + "/gen-hope/programmes/" + e.url)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div
              style={{
                borderRadius: "20px",
                padding: "10px",
                display: "flex",
              }}
            >
              <input
                type="text"
                id="subscribe-input"
                className="uk-input uk-input-default"
              />
              <input
                type="button"
                className="uk-button uk-button-default"
                value="Subscribe"
                onClick={() => subscribeAction()}
              />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
